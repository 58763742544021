div.print {
  page-break-after: always;
}

@media print {
  div.print {
    margin: 0 !important;
  }

  .MuiBox-root {
    margin: 0 !important;
  }

  div {
    padding: 0 !important;
  }
}

@page {
  margin: 0;
}
